import request from '@/utils/request';



// 终端管理---获取配置参数
export function getEdgeEndConfigInfo(params) {
    return request({
        url: "/bivale/end/config/info",
        method: 'post',
        data:params,
    });
}

// 终端管理---保存配置参数
export function saveEdgeEndConfigInfo(params) {
    return request({
        url: "/bivale/end/config/save",
        method: 'post',
        data:params,
    });
}


// 终端管理---获取终端实际设备信息
export function getEdgeEquipmentList(params) {
    return request({
        url: "/bivale/end/edge/list",
        method: 'post',
        data:params,
    });
}

// 终端管理---获取终端平台映射设备信息
export function getEdgeEquipmentMapInfo(params) {
    return request({
        url: "/bivale/end/equipment/map/info",
        method: 'post',
        data:params,
    });
}


// 终端管理---保存终端设备与平台设备映射信息
export function saveEdgeEquipmentMapInfo(params) {
    return request({
        url: "/bivale/end/equipment/map/save",
        method: 'post',
        data:params,
    });
}


// 终端History---获取终端设备History
export function getEdgeHistory(params) {
    return request({
        url: "/bivale/end/history/list",
        method: 'post',
        data:params,
    });
}