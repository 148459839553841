<template>
    <div id="edgeEndSetting"  v-loading="loading" :element-loading-text="$t('alarm.a0')">
        <my-header-title> 终端参数配置信息 </my-header-title>
        <div class="tool">
            <!-- <a-button></a-button> -->
            <div class="tool-left">
                <!-- <span>建筑代码：{{data.gateWayCode}}</span>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <span>智能网关/中间件URL：{{data.gateWayURL}}</span> -->
            </div>
            <a-button type="primary" style="width: 120px"  @click="getConfigInfo()"> {{$t('energy.enedata006')}} </a-button>
        </div>
        
                <!--  -->
                <!--  -->
        <a-row style="border:1px solid  #e8e8e8;height:85%;">
            <a-col :span="16" style="border-right:1px solid  #e8e8e8;padding-right:5px;height:100%;">
                <div class="title">配置信息</div>
                <div class="main" ref="main">
                <a-form-model
                    ref="ruleForm"
                    :rules="rules"
                    :model="form"
                    :label-col="labelCol"
                    :wrapper-col="wrapperCol"
                >
                    <a-form-model-item ref="gateWayCode" label="建筑代码" prop="gateWayCode" >
                        <a-input v-model="form.gateWayCode" :read-only="true" />
                    </a-form-model-item>

                    <a-form-model-item ref="gateWayURL" label="智能网关/中间件URL" prop="gateWayURL" >
                        <a-input v-model="form.gateWayURL" />
                    </a-form-model-item>

                    <a-form-model-item :ref="item.code" :label="item.name" :prop="item.code" v-for="(item,index) in data.values" :key="index">
                        
                        <a-select v-model="form.PROTOCOL_TYPE" :placeholder="item.name" v-if="item.code==='PROTOCOL_TYPE'">
                            <a-select-option  v-for="(val,i) in item.baseValues" :key="i" :value="val.value">
                                {{val.name}}
                            </a-select-option>
                        </a-select>

                        <a-select v-model="form.COMMUNICATION_TYPE" :placeholder="item.name" v-if="item.code==='COMMUNICATION_TYPE'">
                            <a-select-option  v-for="(val,i) in item.baseValues" :key="i" :value="val.value">
                                {{val.name}}
                            </a-select-option>
                        </a-select>

                        <a-select v-model="form.SERVER_TYPE" :placeholder="item.name" v-if="item.code==='SERVER_TYPE'">
                            <a-select-option  v-for="(val,i) in item.baseValues" :key="i" :value="val.value">
                                {{val.name}}
                            </a-select-option>
                        </a-select>

                        <a-input v-model="form.BUILD_CODE" v-else-if="item.code==='BUILD_CODE'" />
                        <a-input v-model="form.DEVICE_ID" v-else-if="item.code==='DEVICE_ID'" />
                        <a-input v-model="form.DEVICE" v-else-if="item.code==='DEVICE'" />
                        <a-input v-model="form.BRAND" v-else-if="item.code==='BRAND'" />
                        <a-input v-model="form.URL" v-else-if="item.code==='URL'" />
                        <a-input v-model="form.USER" v-else-if="item.code==='USER'" />
                        <a-input v-model="form.PASSWORD" v-else-if="item.code==='PASSWORD'" />
                        <a-input v-model="form.CLIENT_ID" v-else-if="item.code==='CLIENT_ID'" />
                        <a-transfer v-if="item.code==='EDGE_TOPIC_VALUE'"
                            :data-source="mockData"
                            :list-style="{
                                width: '280px',
                                height: '300px',
                            }"
                            :target-keys="form.EDGE_TOPIC_VALUE"
                            :render="renderItem"
                            @change="handleChange"
                            :titles="titles"
                            :disabled="form.COMMUNICATION_TYPE!='4'"
                        />

                    </a-form-model-item>
                
                    <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
                    <a-button type="primary" @click="onSubmit">
                        设置
                    </a-button>
                    <a-button style="margin-left: 10px;" @click="resetForm">
                        重置
                    </a-button>
                    </a-form-model-item>
                </a-form-model>
                </div>
            </a-col>
            <a-col :span="8">
                <div class="title">状态信息</div>
                <div class="main">
                    <template>
                        <a-descriptions title="" :column="1">
                            <a-descriptions-item label="网关代码">
                                {{this.data.gateWayCode}}
                            </a-descriptions-item>
                            <a-descriptions-item label="网关地址">
                                {{this.data.gateWayURL}}
                            </a-descriptions-item>
                            <a-descriptions-item label="网关通信状态">
                               
                                 <a-tag color="#87d068" v-if="this.data.deviceStatus===0">
                                    正常
                                </a-tag>
                                <a-tag color="#f50" v-else>
                                    异常
                                </a-tag>
                               
                            </a-descriptions-item>
                            <a-descriptions-item label="网关运行状态">
                                
                                <a-tag color="#e8e8e8" v-if="this.data.systemStatus===0">
                                    未启动
                                </a-tag>
                                <a-tag color="orange" v-else-if="this.data.systemStatus===1">
                                   测试
                                </a-tag>
                                <a-tag color="#87d068" v-else-if="this.data.systemStatus===2" >
                                    运行
                                </a-tag>
                                <a-tag color="#f50" v-else >
                                    停止
                                </a-tag>
                            </a-descriptions-item>
                            <a-descriptions-item label="服务通信类型">
                                <a-tag color="green" v-if="this.form['COMMUNICATION_TYPE']==='4'" >
                                    MQTT
                                </a-tag>
                                
                                <a-tag color="orange" v-else-if="this.form['COMMUNICATION_TYPE']==='1'">
                                    HTTP
                                </a-tag>
                                <a-tag color="cyan" v-else-if="this.form['COMMUNICATION_TYPE']==='2'">
                                    TCP
                                </a-tag>
                                <a-tag color="blue" v-else-if="this.form['COMMUNICATION_TYPE']==='3'">
                                    UDP
                                </a-tag>
                                 <a-tag color="#87d068" v-else-if="this.form['COMMUNICATION_TYPE']==='5'" >
                                    WEBSOCKET
                                </a-tag>
                                <a-tag color="purple" v-else>
                                    其他
                                </a-tag>

                            </a-descriptions-item>
                            <a-descriptions-item label="服务地址">
                                {{this.form['URL']}}
                            </a-descriptions-item>
                            <a-descriptions-item label="服务信息">
                                {{this.form['CLIENT_ID']}}
                            </a-descriptions-item>
                            <a-descriptions-item label="服务运行状态">
                                <a-tag color="#e8e8e8" v-if="this.data.serverStatus===0">
                                    未配置
                                </a-tag>
                                <a-tag color="#f50" v-else-if="this.data.serverStatus===1">
                                    未连接
                                </a-tag>
                                <a-tag color="#87d068" v-else-if="this.data.serverStatus===2" >
                                    已连接
                                </a-tag>
                            </a-descriptions-item>
                            <a-descriptions-item label="刷新时间">
                                {{this.data.date}}
                            </a-descriptions-item>
                        </a-descriptions>
                    </template>

                </div>
            </a-col>
        </a-row>
            <!-- <pre style="height:100%;overflow:auto;">
                {{data}}
            </pre> -->
            <!-- <div style="height:100%;">
                <a-steps v-model="current" type="navigation" size="small" >
                    <a-step status="finish" title="IBMS">
                        <a-icon slot="icon" type="database" />
                    </a-step>
                    <a-step status="finish" title="MIDDLEWARE">
                        <a-icon slot="icon" type="gateway" />
                    </a-step>
                    <a-step status="finish" title="MQTT">
                        <a-icon slot="icon" type="mail" />
                    </a-step>
                    <a-step status="finish" title="EDGE END">
                        <a-icon slot="icon" type="control" />
                    </a-step>
                </a-steps>
            </div> -->
        
    </div>    
</template>
<script>
import {getEdgeEndConfigInfo,saveEdgeEndConfigInfo} from "../../../api/end.js";
import headerTitle from "../../../components/Title/headerTitle";
export default {
    data() {
        return {
            loading: false,
            data:{},
            labelCol: { span: 6 },
            wrapperCol: { span: 17 },
            other: '',
            current: 3,
            stepStyle: {
                marginBottom: '60px',
                boxShadow: '0px -1px 0 0 #e8e8e8 inset',
            },
            form: {
                BUILD_CODE: '',
                DEVICE_ID: '',
                DEVICE: '边缘终端',
                BRAND: 'IBMS',
                URL: '#',
                USER: '',
                PASSWORD: '',
                PROTOCOL_TYPE: '',
                COMMUNICATION_TYPE: '',
                SERVER_TYPE: '',
                EDGE_TOPIC_VALUE:[],
                gateWayCode:'',
                gateWayURL:'',
                CLIENT_ID:'',
            },
            rules: {
                BUILD_CODE: [{ required: true, message: 'Please select Activity zone', trigger: 'change' }],
                DEVICE_ID: [{ required: true, message: 'Please select Activity zone', trigger: 'change' }],
                // DEVICE: [{ required: true, message: 'Please select Activity zone', trigger: 'change' }],
                // BRAND:[{ required: true, message: 'Please select Activity zone', trigger: 'change' }],
                gateWayURL: [{ required: true, message: 'Please select Activity zone', trigger: 'change' }],
                URL: [{ required: true, message: 'Please select Activity zone', trigger: 'change' }],
                // USER: [{ required: true, message: 'Please select Activity zone', trigger: 'change' }],
                // PASSWORD: [{ required: true, message: 'Please select Activity zone', trigger: 'change' }],
                PROTOCOL_TYPE: [{ required: true, message: 'Please select Activity zone', trigger: 'change' }],
                COMMUNICATION_TYPE: [{ required: true, message: 'Please select Activity zone', trigger: 'change' }],
                SERVER_TYPE: [{ required: true, message: 'Please select Activity zone', trigger: 'change' }],
                EDGE_TOPIC_VALUE: [
                    {
                        type: 'array',
                        required: true,
                        message: 'Please select at least one activity type',
                        trigger: 'change',
                    },
                ]
            },
            mockData: [],
            targetKeys: [],
            titles:['未订阅主题','已订阅主题']
            
        };
    },
    components: {
        'my-header-title':headerTitle,
    },
    mounted(){
        this.getConfigInfo();
    },
    methods: {
        renderItem(item) {
            const customLabel = (
                <span class="custom-item">
                {item.name} - {item.value}
                </span>
            );
            // console.log('item', item);
            return {
                key:item.value,
                label: customLabel, // for displayed item
                value: item.value, // for title and filter matching
            };
        },
        handleChange(targetKeys, direction, moveKeys) {
            console.log(targetKeys, direction, moveKeys);
            this.form.EDGE_TOPIC_VALUE = targetKeys;
        },
        getConfigInfo(){
            let params =  {
                action:1,
                clientId: this.$store.getters.clientId,
                siteId: this.$route.query.id,
                sitegrId: this.$store.getters.sitegrId,
                tenantId: 0
            };
            console.log("GetConfigInfo Params",params);
            this.loading = true;
            getEdgeEndConfigInfo(params)
            .then((res) => {

                console.log("LoadData Result",res);

                if(res.errorCode == '00'){
                    this.data = res.data;
                    const {values,gateWayCode,gateWayURL} = this.data;
                    this.form.gateWayCode = gateWayCode;
                    this.form.gateWayURL  = gateWayURL;
                    values.forEach(element => {
                        this.form[element.code] = element.value;
                        if(element.code==='EDGE_TOPIC_VALUE'){
                            element.baseValues.forEach(cele => {
                                cele.key = cele.value;
                            });
                            this.mockData = element.baseValues; 
                            this.form[element.code] = [];
                            if(element.values && element.values.length>0){
                                this.form[element.code] = [];//element.values;
                                element.values.forEach(kelement => {
                                    this.form[element.code].push(kelement.value);
                                });
                            }
                        }
                    });
                        
                    console.log('form',this.form);
                } 

                this.loading = false;
            })
            .catch((err) => {
                this.loading = false;
                console.log(err);
            });
        },
        saveConfigInfo(){
           console.log('form',this.form);
            try {
                let params =  {
                    action:1,
                    clientId: this.$store.getters.clientId,
                    siteId: this.$route.query.id,
                    sitegrId: this.$store.getters.sitegrId,
                    tenantId: 0,
                    gateWayURL:this.form.gateWayURL,
                    list:this.data.values,
                };
                params.list.forEach(element => {
                    if('EDGE_TOPIC_VALUE'===element.code){
                         console.log('EDGE_TOPIC_VALUE',this.form[element.code]);
                        element.value = this.form[element.code].join(',');
                    }else{
                        element.value = this.form[element.code];
                    }
                    
                });
                console.log('param',params);

                this.$confirm({
                    title: "确定要进行设定内容注册吗?",
                    // title:this.$t("energy.enedata184"),
                    centered: true,
                    onOk: () => {
                        this.loading = true;
                        saveEdgeEndConfigInfo(params) .then((res) => {
                            console.log("Update Facility Equipment  res");
                            console.log(res);
                            this.loading = false;
                            if (res.errorCode == "00") {
                                this.$message.success(res.msg);
                                this.getConfigInfo();
                            } else {
                                this.$message.error(res.msg);
                            }          
                        }).catch((err) => {
                            console.log(err);
                            this.loading = false;
                        });
                    }
                });

            } catch (error) {
                console.log('error',error);
            }
           

           
            
        },
        onSubmit() {
            this.$refs.ruleForm.validate(valid => {
                if (valid) {
                    // alert('submit!');
                    this.saveConfigInfo();
                } else {
                    console.log('error submit!!');
                     return false;
                }
            });
        },
        resetForm() {
            this.$refs.ruleForm.resetFields();
        },
    }
}
</script>
<style scoped>
    #edgeEndSetting{
        width: 100%;
        height: 100%;
        padding: 0 20px;
        font-family:apple-system, BlinkMacSystemFont, 'Segoe UI','PingFangSC-Regular', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'
    }
    .tool{
        width: 100%;
        display: flex;
        justify-content:space-between;
        padding: 5px 0;
        /* border-bottom: 1px solid #e8e8e8; */
    }
     .tool-left{
        display: flex;
        justify-content:space-between;
     }
     .tool-left span{
        font-size: 16px;
    }
    .title {
        padding:8px;
        background-color: #e8e8e8;
        font-size: 16px;
    }
    .main{
        width: 100%;
        height: calc(100% - 45px);
        padding: 10px;
        overflow: auto;
        /* border: 1px solid #cccccc; */
    }
</style>